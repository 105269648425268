"use strict";
$(
    function() {
        app.focuser.init();
    }
);

$(
	function() {
		app.assignNumberControl();
		app.setSelectedLink();
		app.addRegisterCheckBoxes();
		app.attachSvgEvents();
		app.makeSocialButtons();
		app.setBackUrls();
		app.addCoverSpan();
		app.saveProductListInfo();
		app.topMenu.init();
		if(!app.isMobile()) {
			$( window ).scroll(
				function(){
					app.floatingHeader();
				}
				);
			app.floatingHeader();
		}
		app.assignProductOver();
		app.initializeScrollButton();
        app.initACBL();
        app.hamburger.init();

        $('.my-cart-line li:first').on('click', function(e) {
        	$(this).toggleClass('opened');
        	app.balloon.close();
        	e.stopPropagation();
        });

        $('.search-form').click(
        	function(e) {
        		e.stopPropagation();
			}
		);

        $(document).click(
        	function() {
                $('.my-cart-line li:first').removeClass('opened');
			}
		);

        app.focuser.init();

        $('button.udu__download-pdf').click(
        	function() {
        		svgEditor.downloadPdf('all');
			}
		);

        $('button.udu__my-files').click(
        	function() {
				app.smf();
			}
		);

        $('button.udu__save-proof').click(
        	function() {
				var idx = svgEditor.currentIndex;
				if (!idx) {
					idx = 0;
				}
				app.blockWindow(true, true);
				app.getScript(
					['https://cdn.uducat.com/static/esb/js/svg-save-to-db.js', 'https://cdn.uducat.com/static/esb/udu-css/css/svg-save-to-db.css'],
					function() {
						app.blockWindow(false);
						app.svg2db.openSaveBox(idx);
					}
				)
			}
		)
    }
);

var app = {
	_vars: {
	    v: 4,
        putAttributesToProdItemsDirectly: true,
        _customFonts: {
            Linearicons: true
        },
		svgEditor: {
	    	deleteImageButtonCaption: {
	    		en: 'Delete Image',
				fr: "Supprimer l'image"
			}
		}
	},
	responsiveMode: true,
	indexLinkPos : 0,
	messages : {},
	productAvailableQuantityUrl : null,
	optionsWereInitialized : false,
	fixTop : 80,
	doAfterAttachSVGLoaded: function(idx) {
		if (Number(idx) === 0) {
			if (app._usrLogged) {
				$('.udu__above-submit-svg-div button').prop('disabled', false);
			} else {
				$('.udu__above-submit-svg-div button:not(.udu__save-proof)').prop('disabled', false);
			}
			mailer.assignMailer('button.udu__email-proof');
		}
	},
	moveProductTime: function() {
		var $tm = $('.udu__prod-time');
		if ($tm.length === 0) {
			return this;
		}
		var $size = $('.udu__size-chart');
		if ($size) {
			$size.before($tm);
		}
	},
	addMyFilesToAccount: function() {
		if (!app._usrLogged) {
			return false;
		}
		var html = '<li class="user-cart"><a href="javascript:app.smf()">' + app.lang.selectWord('Saved Projects', 'Projets enregistrés') + '</a></li>';
		var $ul = $('.add-info ul');
		if ($ul.length === 0) {
			$('#contact-information').before('<fieldset class="add-info"><legend>' + app.lang.selectWord('Additional Information', 'Information Supplémentaire') + '</legend><ul></ul></fieldset>');
			$ul = $('.add-info ul');
		}
		$('.add-info ul').append(html);
	},
	smf: function() {
		app.blockWindow(true, true);
		app.getScript(
			['https://cdn.uducat.com/static/esb/js/svg-save-to-db.js', 'https://cdn.uducat.com/static/esb/udu-css/css/svg-save-to-db.css'],
			function() {
				app.blockWindow(false);
				app.svg2db.openMyFiles();
			}
		)
	},
    runOwlOnIndex: function(params) {
        params.dots = true;
        params.oneRow = true;
        app.owlSlider.makeBanner(params);
		params.loop = false;
		params.autoplay = false;
        app.owlSlider.makeCategoryCarousel(params);
        params.loop = true;
        params.minItemCount = 5;
        app.owlSlider.makeProductListSlider(params);
    },
    runIndexCarusel: function(speed) {
        app.owlSlider.makeCarousel({
            speed: speed
        });
    }
};